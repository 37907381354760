/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 18, 2020 */

@font-face {
    font-family: 'bebas';
    src: url('bebasneue-webfont.woff2') format('woff2'),
         url('bebasneue-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat';
    src: url('montserrat-regular-webfont.woff2') format('woff2'),
         url('montserrat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'tenorsans';
    src: url('tenorsans-regular-webfont.woff2') format('woff2'),
         url('tenorsans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}